import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IUsersFilter,
  ManagerActionsGetRequest,
  ManagerMastersMasterIdCoursesGetRequest,
  ManagerUsersGetRequest,
  ManagerUsersUuidDeleteRequest,
  ManagerUsersUuidGetRequest, ManagerUsersUuidPatchRequest,
  SmartSearchRequest,
  UserBoughtGroupResource,
  UserManagerProfile, UserManagerResource, UserManagerResourcePaginator, UserType,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

type SmartSearchRequestFilter = SmartSearchRequest & { resetPage: boolean }

/**
 * Работа с пользователями системы
 * - получение списка
 * - просмотр и редактирование
 */

@Module({
  dynamic: true,
  name: 'ManagerUsers',
  namespaced: true,
  store,
})
class ManagerUsers extends VuexModule {
  // ---------------------------- Users ---------------------------- >>
  // Filter
  usersFilter: IUsersFilter = { ...GET_DEFAULT_TABLE_FILTER(), itemsPerPage: 10 }
  smartSearchFilter: IUsersFilter = { ...GET_DEFAULT_TABLE_FILTER(), itemsPerPage: 10 }

  // По дефолту ищет почту и заказ
  @Mutation
  setUsersFilter (payload: IUsersFilter, as: UserType = UserType.MANAGER) {
    this.usersFilter = Object.assign({ as }, payload)
  }

  @Mutation
  setSmartSearchFilter (payload: IUsersFilter) {
    this.smartSearchFilter = Object.assign({ groupReturnId: true }, payload)
  }

  // Entities
  users: UserManagerResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setUsers (payload: UserManagerResourcePaginator) {
    this.users = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchUsers () {
    const { data } = await ManagerUsersGetRequest(tableOptionsToParams(this.usersFilter))
    this.setUsers(data)
    return data
  }
  // ---------------------------- User ---------------------------- >>

  user: UserManagerResource | null = null
  userBoughtGroups: UserBoughtGroupResource[] = []

  @Mutation
  setUser (payload: UserManagerResource) {
    this.user = Object.assign({}, payload)
  }

  @Mutation
  setUserBoughtGroups (payload: UserBoughtGroupResource[]) {
    this.userBoughtGroups = [...payload]
  }

  @Action({ rawError: true })
  async fetchUser (userUUID: string) {
    const { data } = await ManagerUsersUuidGetRequest(userUUID)
    this.setUser(data)
    return data
  }

  @Action({ rawError: true })
  async fetchUserGroup (payload: number) {
    const { data } = await ManagerMastersMasterIdCoursesGetRequest(payload)
    this.setUserBoughtGroups(data)
    return data
  }

  @Action({ rawError: true })
  async saveUser (payload: { userUUID: string, params: UserManagerProfile }) {
    const { data } = await ManagerUsersUuidPatchRequest(
      payload.userUUID,
      {
        ...payload.params,
        socialIds: payload.params.socialIds && payload.params.socialIds.length ? payload.params.socialIds : [],
      },
    )
    this.setUser(data)
    return data
  }

  // ---------------------------- Users smart search ---------------------------- >>
  smartSearchUsers: UserManagerResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setSmartSearchUsers (payload: UserManagerResourcePaginator) {
    this.smartSearchUsers = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchSmartSearchUsers(payload: SmartSearchRequestFilter) {
    if (payload.resetPage) {
      this.setSmartSearchFilter({
        ...this.smartSearchFilter,
        page: 1,
      })
    }
    const { data } = await ManagerUsersGetRequest({ search: payload, ...tableOptionsToParams(this.smartSearchFilter) })
    this.setSmartSearchUsers(data)
    return data
  }

  // ---------------------------- Users logs ---------------------------- >>
  @Action({ rawError: true })
  async fetchUserLogs(payload: number) {
    const { data } = await ManagerActionsGetRequest({ userId: payload })
    return data
  }
  // ---------------------------- Users delete ---------------------------- >>
  @Action({ rawError: true })
  async deleteUser(userUUID: string) {
    const { data } = await ManagerUsersUuidDeleteRequest(userUUID)
    return data
  }
}

const ManagerUsersModule = getModule(ManagerUsers)

export default ManagerUsersModule
