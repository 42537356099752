import { render, staticRenderFns } from "./MasterOrdersTable.vue?vue&type=template&id=376e54bd&scoped=true&lang=pug&"
import script from "./MasterOrdersTable.vue?vue&type=script&lang=ts&"
export * from "./MasterOrdersTable.vue?vue&type=script&lang=ts&"
import style0 from "./MasterOrdersTable.vue?vue&type=style&index=0&id=376e54bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376e54bd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCardText,VDataTable})
