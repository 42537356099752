


























import { Component, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import { NameValueResource } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class Select extends DetectSafariMixin {
  @Prop({ default: '' })
  private value!: any

  @Prop({ default: () => ([]) })
  private options!: NameValueResource[]

  @Prop({ default: 'Нет данных' })
  private noDataText!: string

  @Prop({ default: true })
  private outlined!: boolean

  @Prop({
    default: 'default',
    validator (value: string): boolean {
      return !!value.match(/(default|orange)/)
    },
  })
  private skin!: 'default' | 'orange'

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private chips!: boolean

  @Prop({ default: 'value' })
  private itemValue!: string

  private innerValue = ''

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private deleteChip(item: any) {
    if (Array.isArray(this.innerValue)) {
      for (let i = 0; i < this.innerValue.length; i++) {
        if (this.innerValue[i] === item.value) {
          this.innerValue.splice(i, 1)
        }
      }
    }
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
