var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-orders"},[_c('VCardText',{staticClass:"user-orders-sum"},[_c('div',{staticClass:"text-h3-2"},[_vm._v("Принес денег")]),_c('div',{directives:[{name:"price",rawName:"v-price",value:(_vm.user.ordersTotal),expression:"user.ordersTotal"}],staticClass:"text-h3-2"})]),_c('VDataTable',{staticClass:"user-orders__table",attrs:{"headers":_vm.headers,"items":_vm.userOrders.data,"options":_vm.filter,"server-items-length":_vm.userOrders.meta.total,"footer-props":_vm.footerOptions,"hide-default-footer":""},on:{"update:options":function($event){_vm.filter=$event},"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"teal--text text--darken-4"},[_vm._v(_vm._s(item.id))])]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return _vm._l((item.packages),function(course){return _c('div',{directives:[{name:"months-range",rawName:"v-months-range",value:({ months: course.package ? course.package.months : course.months, mask: 'LLLL yyyy' }),expression:"{ months: course.package ? course.package.months : course.months, mask: 'LLLL yyyy' }"}],key:course.id,staticClass:"course-container"})})}},{key:"item.order",fn:function(ref){
var item = ref.item;
return _vm._l((item.packages),function(course){return _c('div',{key:course.id,staticClass:"course-container teal--text text--darken-4"},[_vm._v(_vm._s(course.package ? ((course.package.title) + " " + (course.package.groupType.name)) : ((course.title) + " " + (course.groupType.name))))])})}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.createdAt, localTZ: _vm.isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }),expression:"{ date: item.createdAt, localTZ: isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }"}]})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"product-price"},[(item.packages.length === 1 && item.packages[0].course.type === 'special')?[(item.hasSale)?[(item.totalPrice !== item.originalPrice)?_c('div',{directives:[{name:"number",rawName:"v-number",value:(item.originalPrice),expression:"item.originalPrice"}],staticClass:"text-body-1 product-price-old"}):_vm._e()]:[(item.totalPrice !== item.packages[0].originalPrice)?_c('div',{directives:[{name:"number",rawName:"v-number",value:(item.packages[0].originalPrice),expression:"item.packages[0].originalPrice"}],staticClass:"text-body-1 product-price-old"}):_vm._e()]]:[(_vm.prices[item.id].originalPrice && _vm.prices[item.id].originalPrice !== _vm.prices[item.id].price)?_c('div',{directives:[{name:"number",rawName:"v-number",value:(_vm.prices[item.id].originalPrice),expression:"prices[item.id].originalPrice"}],staticClass:"text-body-1 product-price-old"}):_vm._e()],_c('div',{directives:[{name:"price",rawName:"v-price",value:(_vm.prices[item.id].price),expression:"prices[item.id].price"}],staticClass:"text-body-1"})],2)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Tag',{attrs:{"type":"bright","orderStatus":item.status.value}},[_vm._v(_vm._s(item.status.name))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }