





















import { Component, Prop, Vue } from 'vue-property-decorator'

import Select from '@/components/_uikit/controls/Select.vue'
import { ITableOptions } from '@/store/types'

interface ITableFooterProps {
  options: {
    page: number,
    itemsPerPage: number,
    sortBy: string[],
    sortDesc: boolean[],
    groupBy: string[],
    groupDesc: boolean[],
    multiSort: boolean,
    mustSort: boolean,
  },
  pagination: {
    page: number,
    itemsPerPage: number,
    pageStart: number,
    pageStop: number,
    pageCount: number,
    itemsLength: number,
  },
  itemsPerPageText: string,
}

@Component({
  components: { Select },
})
export default class TableFooter extends Vue {
  @Prop({ required: true })
  private props!: ITableFooterProps

  @Prop({ required: true })
  private filter!: ITableOptions

  private get pageInfo () {
    const { pagination } = this.props
    const itemsLength: number = pagination.itemsLength
    let pageStart: number = pagination.pageStart
    let pageStop: number = pagination.pageStop

    if (pagination.itemsLength && pagination.itemsPerPage) {
      pageStart = pagination.pageStart + 1
      pageStop = itemsLength < pagination.pageStop || pagination.pageStop < 0 ? itemsLength : pagination.pageStop

      return `${pageStart} - ${pageStop} из ${itemsLength}`
    }

    return ''
  }
}
