



















































import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerOrdersModule from '@/store/modules/manager/orders'
import AuthModule from '@/store/modules/auth'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
// types
import { IOrdersFilter, UserManagerResource } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    TableFooter,
    Tag,
  },
})
export default class MasterOrdersTable extends NotifyMixin {
  @Prop({ required: true })
  private user!: UserManagerResource

  private footerOptions = tableFooterOptions
  private filter: IOrdersFilter = Object.assign({}, {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
    userId: this.user.id,
  })

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { sortable: false, text: 'Месяц', value: 'month' },
      { sortable: false, text: 'Заказ', value: 'order' },
      { sortable: false, text: 'Дата и время создания', value: 'date' },
      { sortable: false, text: 'Стоимость, ₽', value: 'price' },
      { sortable: false, text: 'Статус', value: 'status' },
    ]
  }

  private get userOrders () {
    return ManagerOrdersModule.userOrders
  }

  private get stringifyFilter () {
    return JSON.stringify(this.filter)
  }

  private get prices() {
    const orders: Record<number, { originalPrice: number, price: number }> = {}
    this.userOrders.data.map(order => {
      orders[order.id] = {
        originalPrice: order.packages.reduce((acc, val) => acc + val.originalPrice, 0),
        price: order.packages.length > 1 ? order.packages.reduce((acc, val) => acc + val.price, 0) : order.totalPrice,
      }
    })
    return orders
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private fetchOrders() {
    ManagerOrdersModule.fetchOrders({ filter: this.filter })
      .catch(this.notifyError)
  }

  @Watch('stringifyFilter')
  watchFilter () {
    this.fetchOrders()
  }
}
